.right {
  --content-width: 897px;
  //SETTINGS USER, SETTINGS CAMPAIGN
  & > .settings_user_container,
  & > .settings_campaign_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .settings_container {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
      }
    }
  }

  //SETTINGS BILLING
  & > .settings_billing_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
        & > .top {
          align-self: center;
          width: var(--content-width);
          min-width: var(--content-width);
          max-width: var(--content-width);
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          & > .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            &:not(:last-child) {
              margin: 0 28px 0 0;
            }
            width: calc(50% - 14px);
            min-width: calc(50% - 14px);
            max-width: calc(50% - 14px);
            & > *:not(:last-child) {
              margin: 0 0 28px 0;
            }
            & > * {
              width: 100%;
              min-width: 100%;
              max-width: 100%;
            }
          }
          margin: 0 0 28px 0;
        }
        & > .invoices,
        & > .deletebar {
          align-self: center;
          width: var(--content-width);
          min-width: var(--content-width);
          max-width: var(--content-width);
        }
      }
    }
  }

  //SETTINGS CRM
  & > .settings_crm_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        flex: 1;
        & > .crm_container {
          position: relative;
          overflow: hidden;
          & > .crm-items-container {
            flex: 1;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            top: 0;
            transition: left 1s;
            & > .choices {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              & > div:first-child {
                margin: 0 18px 0 0;
              }
            }
            & > div {
              margin-bottom: 32px;
            }
            & > .back {
              margin: 0 0 20px -775px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #343a40;
              cursor: pointer;
              user-select: none;
              touche-action: none;
              &:hover {
                color: #1f2225;
              }
              &:before {
                vertical-align: text-top;
                content: "arrow_back";
                font-family: Material Icons;
                font-weight: normal;
                font-size: 16px;
                color: #343a40;
                margin: 0 5px 0 0;
              }
            }
          }
        }
        & > .crmupgrade {
          align-self: center;
        }
      }
    }
  }
  //SETTINGS API
  & > .settings_api_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    @for $i from 1 through 10 {
      & > div:nth-child(#{$i}) {
        z-index: #{10 - $i};
      }
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
        & > .apistart,
        & > .zapier,
        & > .provider_line,
        & > .apilogs {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin: 0 0 28px 0;
        }
        & > .provider_line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          & > .captain_data,
          & > .phantom_buster {
            &.captain_data {
              margin: 0 28px 0 0;
            }
            flex: 1;
            width: unset;
          }
        }
      }
    }
  }

  //SETTINGS WEBHOOKS
  & > .settings_webhooks_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
        & > .card_webhook_header {
          align-self: center;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin: 0 0 28px 0;
        }
        & > .webhooks_container {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          align-self: center;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          & > .card_webhook_item {
            width: calc(33.33% - 10px);
            margin: 0 15px 15px 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  //SETTINGS WIDGET
  & > .settings_widget_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        &.loading {
          filter: blur(4px);
          pointer-events: none;
          overflow: hidden;
        }
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
        & > .dwnld_widget {
          margin: 0 0 28px 0;
        }
        & > .team_management {
          margin: 0 0 100px 0;
        }
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
      }
    }
  }

  //SETTINGS PRICING
  & > .settings_pricing_container {
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
  }

  //SETTINGS PAYMENT
  & > .settings_payment_container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    padding: 32px 0 0 0;
    & > .column {
      & > .details {
        margin: 0 0 42px 0;
      }
      .lgm_header {
        margin: 0 0 64px 0;
      }
      & > .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #1f2225;
        margin: 0 0 12px 0;
      }
      &:first-child {
        margin-right: 28px;
      }
      & > .cards_payment {
        margin-bottom: 96px;
      }
    }
  }

  //SETTINGS DOWNGRADE
  & > .settings_downgrade_container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
  }

  //SETTINGS GOODBYE
  & > .settings_goodbye_container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
  }

  //SETTINGS TEAMMATES
  & > .settings_teammates_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .manage_teammates {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
      }
    }
  }

  //SETTINGS COMPANY
  & > .settings_company_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: var(--page-min-width-wide);
    height: fit-content;
    &.loading {
      filter: blur(4px);
      pointer-events: none;
      overflow: hidden;
    }

    & > .layout_bars {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    & > .content_wrapper {
      & > .actions_bar {
        margin: 0 48px 0 0;
      }
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 49px 0 50px 70px;
      & > .in {
        width: var(--content-width);
        min-width: var(--content-width);
        max-width: var(--content-width);
      }
    }
  }
}

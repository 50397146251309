//PATH
#root[pathname^="/onboarding"] ~ .intercom-lightweight-app {
  display: none;
}

:root {
  //INTERCOM
  .intercom-lightweight-app-launcher {
    left: 6px !important;
    bottom: 78px !important;
    height: 44px !important;
    width: 45px !important;
    .intercom-lightweight-app-launcher-icon {
      transform: scale(0.75) translateX(-10px) translateY(-10px);
    }
  }

  .intercom-launcher-frame {
    transform: translateX(-21.5px) scale(0.75) !important;
  }

  //GOOGLE CAPTCHA
  .grecaptcha-badge {
    visibility: hidden;
  }
}

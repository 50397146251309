.crop_avatar_wrapper {
  position: relative;
  width: 100vh;
  height: 80vh;

  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;

  display: flex;
  flex-direction: column;

  .crop_avatar_slider {
    position: absolute;
    bottom: -31px;
    background: white;
    height: 31px;
    padding: 0px 15px;
    width: 100%;
    text-align: center;
    .MuiSlider-root {
      width: 50%;
    }
    .MuiSlider-thumb {
      background-color: #1480e6 !important;
    }
    .MuiSlider-track {
      background-color: #1480e6 !important;
    }
  }
  .crop_save {
    position: absolute;
    top: 20px;
    right: 20px;
    // border: solid;
    color: white;
    padding: 10px;

    background: linear-gradient(180deg, #1480e6 0%, #116ec5 100%);
    /* el__(btn_active) */

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &:hover {
      // color: red;
      cursor: pointer;
      background: linear-gradient(180deg, #44a4ff 0%, #3a80c2 100%);
    }
  }
  .crop_cancel {
    position: absolute;
    top: 20px;
    right: 160px;

    color: black;
    background: white;
    padding: 10px;

    // background: linear-gradient(180deg, #1480e6 0%, #116ec5 100%);
    /* el__(btn_active) */

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &:hover {
      // color: red;
      cursor: pointer;
      background: #d7d7d7;
    }
  }
}

@import "./fonts.scss";
@import "./override.scss";

body {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  & > #intercom {
    align-self: stretch;
    border: 1px blue solid;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & > #intercom + #root,
  & > #intercom + .ReactModalPortal {
    max-height: calc(100% - 56px);
  }
  & > #root {
    --page-min-height: 500px;
    align-self: stretch;
    flex: 1;
    min-height: var(--page-min-height);
    max-height: 100%;
    & > .sidebar {
      position: relative;
      & > .left {
      }
      & > .right {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        --page-min-width: 1140px;
        --page-min-width-wide: 1260px;
        --page-min-width-small: 1000px;
        --bar-max-width: 1100px;
        overflow: auto;
        & > .message-aside-container {
          position: absolute;
          right: 0px;
          z-index: 99999999999;
          top: 0px;
          bottom: 0px;
          width: 970px;
          max-width: 970px;
          min-width: 970px;
          transition: transform 0.4s;
          overflow-y: scroll;
          box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.25),
            0px 2px 9px rgba(0, 0, 0, 0.15);
          background: white;
          scroll-behavior: smooth;
        }
        & > .lead-aside-container {
          position: absolute;
          right: 0px;
          z-index: 99999999999;
          top: 0px;
          bottom: 0px;
          width: 519px;
          max-width: 519px;
          min-width: 519px;
          transition: transform 0.4s;
        }
      }
    }
  }
  & > .ReactModalPortal {
    z-index: 1000000;
    .ReactModal__Content {
      background: unset !important;
      overflow: visible !important;
      min-width: 1200px;
      z-index: 1000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      border: none !important;
      & > .modal_content {
        border: none !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 20px;
        & > .in {
          border-radius: 10px;
          margin: auto;
          .graph_preview {
            min-width: 1000px;
            width: 90vw;
          }
        }
      }
    }
  }
}
